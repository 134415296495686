.card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.card-high {
  background-size: cover;
  display: flex;
  align-items: flex-end;
  min-width: 300px;
  height: 360px;
  max-width: 36ch;
  border-radius: 1rem;
  overflow: hidden;
  transition: transform 500ms ease;
  transition: background-size 500ms ease;
}

.card-high:hover,
.card-high:focus-within {
  transform: scale(1.05);
}

.card-content {
  padding: 1.5em 1.5em 0.5em 1.5em;
  background: hsl(0 0% 100% / 0.8);
  background: linear-gradient(
    hsl(0 0% 100% / 0),
    hsl(0 0% 100% / 1) 5%,
    hsl(0 0% 100% / 1)
  );
}

.cardheader {
  font-size: 20px;
  font-weight: bold;
}

.card-p {
  color: rgba(0, 0, 0, 0.75);
}
@media screen and (max-width: 992px) {
  .card-high {
    height: 600px;
  }
}

@media (hover) {
  .card-content {
    transform: translateY(100%);
    transition: transform 500ms ease;
  }
  .card-high:hover .card-content,
  .card-high:focus-within .card-content {
    transform: translateY(0);
  }

  .card-high:focus-within .card-content {
    transition-duration: 0ms;
  }

  .card-content > * {
    opacity: 0;
    transition: opacity 500ms linear;
  }

  .card-high:hover .card-content > *,
  .card-high:focus-within .card-content > * {
    opacity: 1;
  }
}
